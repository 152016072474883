<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item label="组织名称" name="name">
						<a-input v-model:value="formState.name" placeholder="请输入影院组织名称"></a-input>
					</a-form-item>
				</a-row>
				
				<a-row>
					<a-col :span="18">
						<a-button v-permission="['ciname_basis_organize_add']" type="primary" @click="onAdd">添加组织</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>			
			
			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime)}}
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['ciname_basis_organize_edit']" @click="onEdit(record)">
											<a-menu-item>
												修改
											</a-menu-item>
										</div>
										<div v-permission="['ciname_basis_organize_delete']" @click="onDelete(record)">
											<a-menu-item>
												刪除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
			
			<a-modal v-model:visible="showModal" :title="modelRef.id ? '修改影院组织' : '添加影院组织'" @ok="onSubmit">
				<a-spin :spinning="loading">
					<a-form ref="addForm" scrollToFirstError :model="modelRef" name="addForm" :labelCol="{ span: 5 }" :wrapperCol="{span: 16}">
						<a-form-item label="组织名称" name="name" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input v-model:value="modelRef.name" placeholder="请输入影院组织名称"/>
						</a-form-item>
						
						<a-form-item label="地址链接" name="link">
							<a-input v-model:value="modelRef.link" placeholder="请输入地址链接"/>
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getOrganizationList, saveOrganization, updateOrganization, deleteOrganization } from '@/service/modules/cinema.js';
	export default {
		components: {Icon },
		data() {
			return {
				loading: false,
				formState: {
					name: ''
				},
				searchData: {},
				showModal: false,
				modelRef: {
					name: '',
					link: ''
				},
				list: [],
				columns: [
				// 	{
				// 	title: '组织编码',
				// 	dataIndex: 'code'
				// }, 
				{
					title: '组织名称',
					dataIndex: 'name'
				}, 
				// {
				// 	title: '链接地址',
				// 	dataIndex: 'link'
				// }, 
				{
					title: '创建时间',
					key: 'createTime'
				}, 
				{
					title: '操作',
					key: 'action'
				},
				],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onAdd() {
				this.showModal = true;
				this.$nextTick(() => {
					this.$refs.addForm.resetFields();
				});
			},
			onEdit(item) {
				this.modelRef = JSON.parse(JSON.stringify(item));
				this.showModal = true;
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除该组织吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await deleteOrganization({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onSubmit() {
				this.$refs.addForm.validateFields().then(async () => {
					this.loading = true;
					try {
						let ret;
						if(this.modelRef.id) {
							ret = await updateOrganization(this.modelRef);
						} else {
							ret = await saveOrganization(this.modelRef);
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							this.$refs.addForm.resetFields();
							this.showModal = false;
							this.getData();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			}
		}
	}
</script>

<style>
</style>
